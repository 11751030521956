.letu-promo-page {
}


.letu-promo-page__text {
    color: #868686;
    margin-bottom: 8px;
    line-height: 1.5;
    font-weight: 500;
    font-size: 16px;

}

.letu-promo-page__slider {
    margin-bottom: 15px;
    border-radius: 10px;
    overflow: hidden;
}